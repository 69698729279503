import { createSlice,PayloadAction } from '@reduxjs/toolkit';



export const initialState: any = {
    otpValue:'',
    number:'',
    pdfurl:'',
    id:'',
    doc:null,
    fromNumber:'',
    recepientName:'',
    formId:'',
    msgChannel:''
};
export const chat = createSlice({
  name: 'pdf',
  initialState: initialState,
  reducers: {
    setNumber:(state:any,action: PayloadAction<any>) => { 
        state.number=action.payload
      },
    setOtpValue:(state:any,action: PayloadAction<any>) => { 
        state.otpValue=action.payload
      },
    setPdfId:(state:any,action: PayloadAction<any>) => { 
      state.id=action.payload
    },
    setDoc:(state:any,action: PayloadAction<any>) => { 
      state.doc=action.payload
    },
    setFromNumber:(state:any,action: PayloadAction<any>) => { 
      state.fromNumber=action.payload
    },
    setRecepientName:(state:any,action: PayloadAction<any>) => { 
      state.recepientName=action.payload
    },
    setFormId:(state:any,action: PayloadAction<any>) => { 
      state.formId=action.payload
    },
    setMsgChannel:(state:any,action: PayloadAction<any>) => { 
      state.msgChannel=action.payload
    },
    }
});

// this is for dispatch
export const { setNumber,setOtpValue,setPdfId,setDoc,setFromNumber,setRecepientName,setFormId,setMsgChannel } = chat.actions;

// this is for configureStore
export default chat.reducer;